.ar-button {
    position: fixed;
    bottom: 2%;
    right: 25%;
    transform: translateX(1rem);
    z-index: 1000;
    background: none; /* Ensure no background behind the icon */
    transition: background-color 0.2s, border-color 0.2s;
    cursor: pointer;
  }
  
  .ar-button:hover {
    background-color: #fff;
  }
  
  .ar-icon {
    width: 42px; /* Adjust width as needed */
    height: 42px; /* Adjust height as needed */
  }
  
  @media (orientation: landscape) and (max-height: 480px) {
    .ar-button {

    }
  }
  
  @media (orientation: landscape) and (max-height: 390px) {
    .ar-button {
 
    }
  }
  
  @media (orientation: landscape) and (max-height: 375px) {
    .ar-button {

    }
  }
  
  @media (orientation: portrait) {
    .ar-button {

    }
  }
  