.color-choice-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
}

.color-choice-row {
  display: flex;
  justify-content: center;
}

.header-text {
  color: #525251;
  margin-top: 10px;
  padding-top: 0.25rem;
  padding-bottom: 12px;
  font-weight: bold;
}

@media (orientation: landscape) and (max-height: 480px) {
  .header-text {
    font-size: 0.8rem;
  }

  .color-choice-container {}
}