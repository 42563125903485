.slider-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  font-family: "Montserrat", sans-serif;
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #00000033;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
}

.slider-part{
  display: flex;
  align-items: center;
}

.inner-wrapper {
  background-color: #dcdcdc;
  display: flex;
  flex-direction: row;
  padding: 1px 1px 1px 1px;
  user-select: none;
  outline: none;
  border: none;
  width: 90%;
}

.custom-dimension-text{
  display: flex;
  justify-content: center;
  margin-right: 30px;
  font-size: 13.5px;
  color: #555;
  text-align: center;
  margin-bottom: 12px;
  margin-top: 2.5px;

}

.input-container {
  display: flex;
  background-color: #dcdcdc;
 

}

.fake-input {
  background-color: #f5f4f4;
  color: #525251;
  font-weight: 700;
  font-size: 1.3rem;
  text-align: center;
  width: 30%;
  user-select: none;
  outline: none;
  border-right: 2px solid #00000033;
}

.unit {
  font-weight: 700;
  font-size: 1.3rem;
  pointer-events: none;
  color: #525251;
  text-align: left;

}



.dimension-input {
  margin-right: 10px;
  border: none;
  background: transparent;
  color: #525251;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  outline: none;
}

.dimension-info-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}



.range-warning {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
}

.info-icon2 {
  position: absolute;
  top: 40%;
  width: 15px;
  height: 15px;
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  text-align: center;
  line-height: 12px;
  font-size: 10.5px;
  cursor: pointer;
  border: 1.5px solid black;
  box-sizing: border-box;
  padding-top: 0.75px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@media (orientation: landscape) and (max-height: 480px) {
  .dimension-input {
    font-size: 0.8em;
    margin-right: 5px;
  }

  .fake-input {
    font-size: 0.9em;


  }
  .custom-dimension-text{
    font-size: 0.6em;
  }

  .unit {
    font-size: 0.9em;

  }

  .info-icon2 {
    left: 1%;
  }
}

@media (orientation: landscape) and (max-height: 412px) {
  .info-icon2 {
    left: 1%;
  }

  .dimension-input {
    font-size: 0.8em;
    margin-right: 5px;
    ;

  }
  .custom-dimension-text{
    font-size: 0.6em;
  }

  .fake-input {
    font-size: 0.9em;

  }

  .unit {
    font-size: 0.9em;

  }
}

@media (orientation: landscape) and (max-height: 480px) {
  .dimension-input {
    font-size: 0.8em;
    margin-right: 5px;
  }

  .fake-input {
    font-size: 0.9em;

  }
  .custom-dimension-text{
    font-size: 0.6em;
  }

  .unit {
    font-size: 0.9em;

  }

  .info-icon2 {
    left: 1%;
  }
}

@media (orientation: landscape) and (max-height: 392px) {
  .info-icon2 {
    left: 1%;
  }

  .dimension-input {
    font-size: 0.8em;
    margin-right: 5px;
  }
  .custom-dimension-text{
    font-size: 0.6em;
  }

  .fake-input {
    font-size: 0.9em;
  }

  .unit {
    font-size: 0.8em;
  }
}


@media screen and (min-resolution: 144dpi) {
  .slider-container {
    margin-top: 10px; 
    margin-bottom: 10px;
    border-bottom: 1px solid #00000033;
  }

  .custom-dimension-text {
    font-size: 11px; 
    margin-right: 25px; 
    margin-bottom: 13px; 
    margin-top: 1px; 
  }

  .fake-input {
    font-size: 1rem; 
    width: 55%; 
  }

  .unit {
    font-size: 1rem; 
  }

  .dimension-input {
    margin-right: 4px; 
    font-size: 0.8rem; 
  }

  .range-warning {
    font-size: 1rem; 
    margin-top: 3px; 
  }

  .info-icon2 {
    width: 12px; 
    height: 12px; 
    line-height: 10px; 
    font-size: 9px; 
    border: 1px solid black; 
    padding-top: 1px; 
  }
}