.summary-input-container {
  display: flex;
  align-items: center;
  padding: 12px;
}

.input-icon {
  height: 30px;
  width: 30px;
  padding-right: 2px;
  object-fit: contain;
  aspect-ratio: 3/2;
}

.feedback-container {
  padding: 18px; 
}

.aesthetic-input.feedback {
  height: 15vh; 
  resize: none; 
}



.aesthetic-input {
  background-color: transparent;
  border-bottom: 1px solid #666;
  color: #fff;
  height: 6vh;
  width: 18vw;
  margin-left: 10px;
}

.aesthetic-input::placeholder {
  color: #d6d6d6;
}


.aesthetic-input.invalid {
  border-bottom: 1px solid #82c225;
}

.aesthetic-input:focus {
  border-bottom: 1px solid #fff;
  outline: none;
}

@media (orientation: landscape) and (max-height: 480px) {
  .summary-input-container {
    padding: 6px;
  }

  .aesthetic-input {
    font-size: 0.7em;
    width: 25vw;
  }

  .input-icon {
    margin-right: 2px;
    height: 30px;
    width: 30px;
  }
}