@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

.summary-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #82c225;
    width: auto;
    height: auto;
    padding: 10px 30px 10px 30px;
    transition: background-color 0.2s;

}

.summary-button:hover {
    background-color: #2b2b2b;
    border: 1px solid #82c225;
    transition: background-color 0.2s;

}

.summary-text {
    color: #ffffff;
    text-shadow: 1px 1px 2px black;
    font-size: 0.9em;
    text-align: center;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
}

@media (orientation: landscape) and (max-height: 480px) {
    .summary-button {
        transform: translate(-43%, -50%);
        pointer-events: auto;

    }

    .summary-text {
        font-size: 0.7em;
    }
}
@media (orientation: portrait) {
    .summary-button {
      display: none;
    }
  }