.color-choice-rhombus-wrapper {
  height: 70px;
  cursor: pointer;
  display: flex;
  margin-bottom: 50px;
  
}

.color-choice-rhombus:hover::after {
  opacity: 1;
}

.color-choice-rhombus::after {
  content: attr(data-hover-text);
  position: absolute;
  font-size: 13px;
  text-align: center;
  margin-top: 5px;
  margin-left: -2.25px;
  width: 72px;
  transition: 0.3s;
  opacity: 0;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.color-choice-rhombus-selected::after {
  opacity: 1;
}

.color-choice-rhombus {
  max-width: 72px;
  height: 70px;
}

.color-choice-rhombus img {
  width: 90%;
  height: 110%;
  object-fit: cover;
}

.color-choice-rhombus:hover img {
  filter: brightness(50%);
  transform: scale(1.05);
  transition: all 0.1s ease-in-out;
}

.color-choice-rhombus-selected img {
  transform: scale(1.1);
  transition: all 0.1s ease-in-out;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .color-choice-rhombus-wrapper {}

  .color-choice-rhombus-border {}

  .color-choice-rhombus {}
}

@media (min-width: 1921px) {
  .color-choice-rhombus-wrapper {}

  .color-choice-rhombus-border {}

  .color-choice-rhombus {}
}


@media (orientation: landscape) and (max-height: 480px) {
  .color-choice-rhombus {
    max-width: 60px;
    height: 62px;
  }
  
  .color-choice-rhombus-border {}

  .color-choice-rhombus-wrapper {
    height: 95px;

  }

  .color-choice-rhombus::after {
    margin-left: -7px;
    font-size: 10px;
 
    
  }
}

@media (orientation: landscape) and (max-height: 360px) {
  .color-choice-rhombus-wrapper::after {}
}