.colorgrid {
    gap: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

@media (orientation: landscape) and (max-height: 480px) {
    .colorgrid {
        gap: 20px;
        margin-bottom: 0px;
    }
}

@media (orientation: landscape) and (max-height: 360px) {
    .colorgrid {
        gap: 20px;
        margin-bottom: 0px;
        
    }
}

@media (orientation: landscape) and (max-height: 392px) {
    .colorgrid {
        gap: 20px;
      
    }
}