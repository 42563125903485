/* HighlightedCheckboxComponent.css */
.highlighted-checkbox-wrapper {
  display: flex;
  align-items: center;
}

.highlighted-custom-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 18px;
  background-color: white;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-right: 10px;
}

.highlighted-custom-checkbox:checked {
  background-color: #82c225;
}

.highlighted-custom-checkbox:disabled {
  background-color: rgb(131, 131, 131);
  cursor: not-allowed;
}

.base-label {
  font-size: 16px; /* Adjust as needed */
}

.label-selected {
  color: #416013;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-decoration-color: #82c225;
  font-weight: bold;
}

.label-unselected {
  color: gray;
  font-weight: normal;
}

.info-icon11 {
  margin-left: 10px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: #f0f0f0;
  color: #000;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  border: 1px solid #000;
  user-select: none;
}

@media (orientation: landscape) and (max-height: 480px) {
  .base-label {
    font-size: 1em;
  }
}
