.panel-color-choice-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 50px;
}

.panel-color-choice-row {
  display: flex;
  justify-content: center;
}

.cursive-text {
  font-style: italic;
}

.panel-color-disabled-text {
  color: red;
  font-style: italic;
  font-size: 15px;
}

.panel-color-disabled-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.363);
}

@media (orientation: landscape) and (max-height: 480px) {
  .panel-color-disabled-text {
    font-size: 0.7em;
  }
  .panel-color-choice-container {
    margin-bottom: 20px;
  }
}