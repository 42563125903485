.feedback-container {
    display: flex;
    align-items: center;
    padding: 18px; /* Consistent with the expanded functionality */
  }
  
  .input-icon {
    height: 30px;
    width: 30px;
    padding-right: 2px;
    object-fit: contain;
    aspect-ratio: 3/2;
  }
  
  .feedback-input {
    background-color: transparent;
    border-bottom: 1px solid #666;
    color: #fff;
    font-family: 'Gothic A1', sans-serif;
    height: 15vh; /* Adjust the height to be 2.5 times of the SummaryInput */
    font-size: 18px;
    flex: 1;
    width: 100%; /* This makes the input stretch across the container */
    resize: none; /* Prevent resizing since it's a textarea */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  .feedback-input::placeholder {
    color: #d6d6d6;
  }
  
  .feedback-input.invalid {
    border-bottom: 1px solid #82c225;
  }
  
  .feedback-input:focus {
    border-bottom: 1px solid #fff;
    outline: none;
  }
  
  @media (orientation: landscape) and (max-height: 480px) {
    .feedback-container {
      padding: 6px;
    }
  
    .feedback-input{
  /* Increased due to larger baseline height */
      font-size: 0.7em;
    }
  
    .input-icon {
      margin-right: 2px;
    }
  }
  