.info-window {
  width: 80%;
  height: 90%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 15px;
  overflow-y: auto; /* Enable vertical scrolling */
  box-sizing: border-box; /* Ensure padding is included in height */
}

.info-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.471);
  cursor: pointer;
}

.info-image {
  width: 80%;
  max-height: 60%;
  object-fit: contain;
}

.info-text {
  text-align: center;
  font-size: 18px;
  padding: 0 20px;
  color: white;
  /* max-height: 150px; Remove or comment this line */
  /* overflow-y: auto; Remove this line */
}

.info-title {
  font-size: 28px;
  color: white;
}

@media (orientation: landscape) and (max-height: 480px) {
  .info-title {
    font-size: 1em;
  }

  .info-text {
    font-size: 0.6em;
  }
}

@media (orientation: landscape) and (max-height: 392px) {
  .info-title {
    font-size: 1em;
  }

  .info-text {
    font-size: 0.6em;
  }
}
