.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 998;
  pointer-events: auto;
  background-color: rgba(43, 43, 43, .2);
}

.popup-content {
  position: relative; /* Added to make this a reference for absolute children */
  z-index: 999;
  flex-direction: column;
  color: #fff;
  padding: 36px 144px 36px 144px;
  pointer-events: auto;
  max-width: 90vw;
  background-color: rgba(0, 0, 0, .85);
  overflow-y: auto;
}

.close-btn {
  position: absolute;
  top: 0; /* Align to the top edge of the popup-content */
  right: 0; /* Align to the right edge of the popup-content */
  background-color: rgba(247, 247, 247, 0.471);
  cursor: pointer;
  width: 30px;
  height: 30px;
  /* Ensure the X is visible and centrally aligned */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333; /* Assuming you want the X to be more visible */
}

.finalization-summary-container {
  display: flex;
  flex-direction: column;
}

.summary-info-close {
  background-color: rgb(247, 247, 247);
  cursor: pointer;
}

.form-one,
.form-two {
  margin-bottom: 15px;
}

input {
  margin-right: 10px;
}

.input-fields {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.summary-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.feedback-row {
  display: flex;
}

.close-btn:disabled {
  background-color: #555;
  color: #777;
  cursor: not-allowed;
}

.finalization-container {
  display: flex;
  align-items: center;
}

.spinner {
  border: 10px solid rgb(255, 255, 255);
  border-top: 10px solid #82c225;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 1s linear infinite;
  position: absolute;
  bottom: 50%;
  left: calc(50% - 25px);
  z-index: 999;
}

.loading-text {
  position: absolute;
  bottom: 120px; /* Adjust as necessary for spacing */
  color: rgb(255, 255, 255);
  font-size: 20px;
  text-align: center;
  left: calc(50% - 145px);
  z-index: 999;
  text-shadow: 
    -1px -1px 0 #000,  
     1px -1px 0 #000,
    -1px  1px 0 #000,
     1px  1px 0 #000;
}
.postal-description {
  color: #cccccc; /* Light gray color for the text */
  font-size: 14px; /* Smaller font size for subtlety */
  margin-top: 5px; /* Spacing above the text for separation */
  margin-left: 10px;
  width: 100%; /* Full width to ensure it spans the container */
}

.cost-description {
  color: #ff0000; /* Light gray color for the text */
  font-size: 14px; /* Smaller font size for subtlety */
  margin-top: 5px; /* Spacing above the text for separation */
  margin-left: 10px;
  width: 100%; /* Full width to ensure it spans the container */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.buttons-row {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.captcha {
  display: flex;
  align-items: center;
  width: 100%;
}

.captchaquestion {
  display: flex;
}

.captchaquestion .aesthetic-input {
  text-align: center;
  flex: none;
  width: 55px;
  font-size: 18px;
  padding: 4px;
  align-items: center;
}

.questionspan {
  margin-top: 2px;
  margin-left: 3vw;
  display: inline-flex;
  align-items: center;
}

.captcha span {
  font-size: 18px;
  margin-top: 2px;
}

.captchaquestion span {
  font-size: 18px;
}

.captcha span,
.captchaquestion span {
  font-size: 18px;
  line-height: 7vh;
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
}

@media (orientation: landscape) and (max-height: 480px) {
  .captchaquestion span {
    font-size: 0.8rem;
  }

  .captcha span {
    font-size: 0.8rem;
  }

  .captchaquestion .aesthetic-input {
    font-size: 0.8rem;
  }
  .popup-content {
    background-color: rgba(0, 0, 0, 1);
  }
  .spinner {
    bottom: 25px;

  }
  .loading-text {
    left: calc(50% - 170px);
  }

}

.privacy-policy {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.privacy-policy input[type='checkbox'] {
  margin-right: 10px;
}

.privacy-policy a {
  color: #0066cc;
  text-decoration: underline;
  cursor: pointer;
}

.privacy-policy a:hover {
  color: #0055a5;
}

@media (orientation: landscape) and (max-height: 480px) {
  .popup-content {
    width: 80vw;
    max-height: 90vh;
    background-color: rgba(0, 0, 0, 1);
    padding: 36px;
  }
  .postal-description {
    font-size: 9px;
  }
}

@media (orientation: landscape) and (max-height: 360px) {
  .privacy-policy {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 9px;
    margin-left: 3vw;
    margin-bottom: 1vh;
  }
  .popup-content {
    width: 80vw;
    max-height: 90vh;
    padding: 36px;
    background-color: rgba(0, 0, 0, 1);
  }
  .postal-description {
    font-size: 9px;
  }
}

@media (orientation: landscape) and (max-height: 392px) {
  .privacy-policy {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 9px;
    margin-left: 3vw;
    margin-bottom: 1vh;
  }
  .popup-content {
    width: 80vw;
    max-height: 90vh;
    background-color: rgba(0, 0, 0, 1);
  }
  .postal-description {
    font-size: 9px;
  }
}

@media (orientation: portrait) {
  .popup-overlay {
    display: none;
  }
}

input:-internal-autofill-selected {
  background-color: transparent;
  color: white;
}
