.config-block {
    position: fixed;
    top: 0;
    right: 0;
    height: 91.8vh;
    width: 530px;
    padding: 2vh 1% 2vh 0;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }


@media (orientation: landscape) and (max-height: 480px) {
    .config-block {
        width: 48vw;
        height: 100vh;
        padding: 3.35vh 1% 2vh 0;
        margin-top: 1px;
    }
}

@media (orientation: landscape) and (max-height: 392px) {
    .config-block {
        margin-top: 2px;
    }
}
@media (orientation: landscape) and (max-height: 392px) {
    .config-block {
        margin-top: 3px;
    }
}