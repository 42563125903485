@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.transition-top {
  transition: top 0.1s ease;
}

a[href*="https://www.soft8soft.com/verge3d-trial/"] {
  display: none;
}

@keyframes fadeInDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}


@keyframes fadeInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-fadeInFromLeft {
  animation-name: fadeInFromLeft;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}



.animate-fadeIn {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animate-fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 1s;
  animation-fill-mode: both;
}



body {
  margin: 0;
}

.transition-list {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.1s ease-in-out;
}

.transition-list.open {
  max-height: 2000px;
  /* set this to a max value that your list can get */
}


.apps-container {
  --apps-container-gap: 10px;
  --apps-container-cell-height: 100vh;

  width: auto;
  min-height: calc(calc(var(--apps-container-cell-height) * 2) + var(--apps-container-gap));
  margin: 0 auto;
  padding: 10px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: var(--apps-container-gap);
}

.control-buttons {
  padding: 10px;
}

.control-buttons__button {
  margin: 5px;
}

.v3d-simple-preloader-logo {
  background-image: url("/public/mh.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}


.fixed-bottom-right {
  position: fixed;
  bottom: 2%;
  right: 2vh;
}

#loadingText {
  animation: fadeIn 1s ease-in-out;
}

@media (orientation: landscape) and (max-height: 480px) {
  .fixed-bottom-right {
    bottom: 10px;
    left: 80px;
    opacity: 0.8;
    pointer-events: none;
  }
}


.price-calculator {
  position: fixed;
  bottom: 1rem;
  left: 8rem;
  display: none;
}
