.checkbox-only-wrapper {
  display: flex;
  justify-content: space-between;

}

.checkbox-only-wrapper2 {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

}


.custom-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 18px;
  background-color: white;
  position: relative;
  top: 2px;

}



.custom-checkbox:checked {
  background-color: #82c225;
}

.custom-checkbox:disabled {
  background-color: rgb(131, 131, 131);
  cursor: not-allowed;
}

.custom-checkbox:disabled+.checkbox-border {
  background-color: lightgray;
}

.custom-checkbox:checked+.checkbox-border {
  transform: scale(1);
}

.info-icon3 {

  margin-top: 3px;
  width: 20px;
  height: 20px;
  background-color: #f0f0f0;
  color: #000;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #000000;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}