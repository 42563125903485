.close-button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(247, 247, 247, 0.471);
    cursor: pointer;
    width: 30px;
    height: 30px;
}

@media (orientation: landscape) and (max-height: 480px) {
    .close-button {
        width: 25px;
        height: 25px;
    }
}