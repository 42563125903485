.send-pdf-btn {
  display: block;
  margin: auto;
  background-color: #82C225;
  color: #fff;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 18px;
}

.send-pdf-btn.disabled {
  background-color: #555;
  color: #d6d6d6;
  cursor: not-allowed;
}

@media (orientation: landscape) and (max-height: 480px) {
  .send-pdf-btn {
    padding: 10px 25px;
    font-size: 0.9rem;
  }
}
