.panel-color-choice-rhombus-wrapper {
  display: flex;
  height: 70px;
  cursor: pointer;
  margin-bottom: 40px;
}

.panel-color-choice-rhombus::after {
  content: attr(data-hover-text);
  position: absolute;
  font-size: 11px;
  opacity: 0;
  width: 72px;
  margin-top: 5px;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.panel-color-choice-rhombus:hover::after {
  opacity: 1;
}

.panel-color-choice-rhombus-selected::after {
  opacity: 1;
}

.panel-color-choice-rhombus {
  max-width: 72px;
  height: 70px;
}

.panel-color-choice-rhombus img {
  width: 90%;
  height: 110%;
  object-fit: cover;
}

.panel-color-choice-rhombus:hover img {
  filter: brightness(50%);
  transform: scale(1.05);
  transition: all 0.1s ease-in-out;
}

.panel-color-choice-rhombus-selected img {
  transform: scale(1.1);
  transition: all 0.1s ease-in-out;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
}

.hidden {
  display: none;
}

@media (orientation: landscape) and (max-height: 480px) {
  .panel-color-choice-rhombus {
    max-width: 60px;
    height: 62px;
  }

  .panel-color-choice-rhombus-border {}

  .panel-color-choice-rhombus-wrapper {
    height: 100px;
  }
  .panel-color-choice-rhombus::after {
    font-size: 10px;
    margin-left: 3px;

  }
}

@media (orientation: landscape) and (max-height: 392px) {
  .panel-color-choice-rhombus-wrapper::after {}
}


@media (orientation: landscape) and (max-height: 360px) {
  .panel-color-choice-rhombus::after {
    font-size: 10px;
    margin-left: 3px;

  }
}