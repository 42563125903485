.clickable-squares-container {
  width: 85%;
  height: 26vw;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  margin-bottom: 20px;
}

.clickable-square {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  transition: background-color 0.3s;
}

.resetbutton {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  right: 10px;
  bottom: 10px;
  display: none;
}

