.mobileinfo-icon-div {
    position: fixed;
    display: none;
}

.mobileinfo-icon-id {
    max-width: 100px;
    max-height: 100px;
}

@media (orientation: landscape) and (max-height: 480px) {
    .mobileinfo-icon-div {
        display: block;
        left: 22.5rem;
        bottom: 2rem;
    }
    
    .mobileinfo-icon-id {
        max-width: 70px;
        max-height: 70px;
    }
}

@media (orientation: landscape) and (max-height: 412px) {
    .mobileinfo-icon-div {
        display: block;
        left: 42%;
        bottom: 8%;
    }
    
    .mobileinfo-icon-id {
        max-width: 70px;
        max-height: 70px;
    }
}

@media (orientation: landscape) and (max-height: 392px) {
    .mobileinfo-icon-div {
        display: block;
        left: 22rem;
        bottom: 2rem;
    }
    
    .mobileinfo-icon-id {
        max-width: 70px;
        max-height: 70px;
    }
}

@media (orientation: landscape) and (max-height: 375px) {
    .mobileinfo-icon-div {
        display: block;
        left: 42%;
        bottom: 8%;
    }
    
    .mobileinfo-icon-id {
        max-width: 70px;
        max-height: 70px;
    }
}
@media (orientation: landscape) and (max-height: 360px) {
    .mobileinfo-icon-div {
        display: block;
        left: 19rem;
        bottom: 2rem;
    }
    
    .mobileinfo-icon-id {
        max-width: 70px;
        max-height: 70px;
    }
}

@media (orientation: landscape) and (max-height: 392px) {
    .mobileinfo-icon-div {
        display: block;
        left: 41%;
        bottom: 8%;
    }
    
    .mobileinfo-icon-id {
        max-width: 70px;
        max-height: 70px;
    }
}
@media (orientation: landscape) and (max-height: 360px) {
    .mobileinfo-icon-div {
        display: block;
        left: 41%;
        bottom: 8%;
    }
    
    .mobileinfo-icon-id {
        max-width: 70px;
        max-height: 70px;
    }
}

@media (orientation: landscape) and (max-height: 844px) {}

/*  iPhone 13 mini, iPhone 12 mini, iPhone 11 Pro, iPhone Xs, and iPhone X */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    .mobileinfo-icon-div {
        display: block;
        left: 20rem;
        bottom: 2rem;
    }

    .mobileinfo-icon-id {
        max-width: 70px;
        max-height: 70px;
    }
}

/*  iPhone 13, iPhone 12 & 12 Pro */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    .mobileinfo-icon-div {
        display: block;
        left: 19rem;
        bottom: 2rem;
    }
    .mobileinfo-icon-id {
        max-width: 70px;
        max-height: 70px;
    }
}

/*  iPhone 13 Pro Max & iPhone 12 Pro Max */
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {

    .mobileinfo-icon-div {
        display: block;
        left: 21rem;
        bottom: 2rem;
    }

    .mobileinfo-icon-id {
        max-width: 70px;
        max-height: 70px;
    }
}

/*iPhone 14*/
@media only screen and (width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {

    .mobileinfo-icon-div {
        display: block;
        left: 19rem;
        bottom: 2rem;
    }

    .mobileinfo-icon-id {
        max-width: 70px;
        max-height: 70px;
    }

}

/*iPhone 14 Pro*/
@media only screen and (width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    .mobileinfo-icon-div {
        display: block;
        left: 19rem;
        bottom: 2rem;
    }

    .mobileinfo-icon-id {
        max-width: 70px;
        max-height: 70px;
    }
}

/*iPhone 14 Pro Max*/
@media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    .mobileinfo-icon-div {
        display: block;
        left: 19rem;
        bottom: 2rem;
    }

    .mobileinfo-icon-id {
        max-width: 70px;
        max-height: 70px;
    }
}
