.rhombus-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap;

}

.rhombus-row {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 50px;
}

@media (orientation: landscape) and (max-height: 480px) {
  .rhombus-container {}

  .rhombus-row {}
}

@media (orientation: landscape) and (max-height: 392px) {
  .rhombus-row {}

  .rhombus-container {}
}

@media (orientation: landscape) and (max-height: 360px) {
  .rhombus-row {}

  .rhombus-container {}
}