.rotate-icon-div {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    padding: 20px;
}

.rotate-icon-id {
    max-width: 200px;
    max-height: 200px;
}

@media (orientation: portrait) {
    .rotate-icon-div {
        display: block;
    }
}