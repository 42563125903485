.plus-checkbox-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 4px;
    cursor: pointer; /* Ensures the cursor indicates a clickable area */
  }
  
  .plus-checkbox-wrapper.disabled {
    cursor: not-allowed;
  }
  
  .plus-checkbox-only-wrapper {
    position: relative;
    display: flex;
    margin-left: 5px;
    cursor: pointer; /* Ensures the cursor indicates a clickable area */
  }
  
  .custom-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px; /* Increased size for better clickability */
    height: 20px; /* Increased size for better clickability */
    background-color: white;
    position: relative;
    z-index: 1; /* Ensures the checkbox is on top */
    cursor: pointer; /* Ensures the cursor indicates a clickable area */
  }
  

  .custom-checkbox:checked {
    background-color: #475352;
  }
  
  .custom-checkbox:disabled {
    background-color: rgb(131, 131, 131);
    cursor: not-allowed;
  }
  
  .custom-checkbox:disabled + .checkbox-border {
    background-color: lightgray;
  }
  
  .custom-checkbox:checked + .checkbox-border {
    transform: scale(1);
  }
  
  .checkbox-toggle-icon {
    width: 20px; /* Increased size for better visibility */
    height: 20px; /* Increased size for better visibility */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 4px;
    cursor: pointer; /* Ensures the cursor indicates a clickable area */
  }
  
  .checkbox-toggle-icon:before,
  .checkbox-toggle-icon:after {
    content: '';
    position: absolute;
    background-color: #000000cc;
    border-radius: 1px;
  }
  
  .checkbox-toggle-icon:before {
    width: 2px;
    height: 15px;

  }
  
  .checkbox-toggle-icon:after {
    width: 16px;
    height: 2px;

  }
  
  .checkbox-toggle-icon.open:before {
    width: 15px;
    height: 2px;
  }
  
  .checkbox-toggle-icon.open:after {
    display: none;
  }
  
  label {
    cursor: pointer;/* Ensures the cursor indicates a clickable area */
  }
  