.rectangle {
    width: 6vw;
    height: 16.2vw;
    background-color: #afafaf;
    position: absolute;
    bottom: 1.75vw;
    left: 39.28%;
}

.square {
    width: 4.7vw;
    height: 14.4vw;
    background-color: #d6d6d6;
    position: absolute;
    bottom: 3vw;
    left: 42.28%;
}

.rectangle-variant {
    width: 5.4vw;
    height: 18.5vw;
    background-color: #afafaf;
    position: absolute;
    bottom: 0;
    left: 40%;
}

.square-variant {
    width: 4.6vw;
    height: 15.8vw;
    background-color: #d6d6d6;
    position: absolute;
    bottom: 1.8vw;
    left: 41.85%;
}

.rectangle1b {
    width: 10.6vw;
    height: 14.7vw;
    background-color: #afafaf;
    position: absolute;
    bottom: 4.2vw;
    left: 34%;
}

.square1b {
    width: 9.55vw;
    height: 13vw;
    background-color: #d6d6d6;
    position: absolute;
    bottom: 5.4vw;
    left: 36%;
}


.rectangle-variant1b {
    width: 10.6vw;
    height: 18.3vw;
    background-color: #afafaf;
    position: absolute;
    bottom: 1.25vw;
    left: 34%;
}


.square-variant1b {
    width: 9.3vw;
    height: 16.7vw;
    background-color: #d6d6d6;
    position: absolute;
    bottom: 2.2vw;
    left: 36.4%;
}


@media (orientation: landscape) and (max-height: 480px) {
    .rectangle {
        width: 10vw;
        left: 32%;
    }

    .square {
        width: 9vw;
        left: 33.5%;
    }

    .rectangle-variant {
        width: 11vw;
        left: 31%;

    }

    .square-variant {
        width: 9vw;
        left: 34%;
    }

    .rectangle1b {
        width: 17.5vw;
        left: 28%;
    }

    .square1b {
        width: 16.6vw;
        left: 29%;
    }

    .rectangle-variant1b {
        width: 19.2vw;
        left: 26.8%;
    }

    .square-variant1b {
        width: 17.6vw;
        left: 28.8%;
    }



}


@media (orientation: landscape) and (max-height: 392px) {}