.checkbox-container {
  margin-top: 10px;
  color: white;
  background-color: "rgba(202, 203, 202, 0.7)";
  padding-bottom: 15px;
  border-radius: 0.375rem;
  font-family: "Montserrat", sans-serif;
}

.checkbox-container label {
  margin-top: 0px;
  color: black;
}

.options-element {
  padding-left: 4px;
  padding-bottom: 4px;
}


.options-single-element {
  padding-left: 22px;
}


.indent-option {
  margin-left: 32px;
  margin-top: 3px;
  margin-bottom: 5px;
}

.disabled-text {
  color: #ff0000cd;
  font-style: italic;
  font-size: 15px;
}

.checkbox-container .option-selected {
  color: black;
  font-weight: 500;
}

.checkbox-container .option-unselected {
  color: gray;
  font-weight: lighter;
}

.checkbox-container .header-unselected {
  color: rgb(90, 90, 90);
  font-weight: bolder;
}

.checkbox-container .header-selected {
  color: black;
  font-weight: bolder;
}

.disabled-container {
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.363);
}

@media (orientation: landscape) and (max-height: 480px) {
  .checkbox-container {
    font-size: 0.7em;
  }

  .disabled-text {
    font-size: 0.7em;
  }
}

.additional-checkboxes {
  display: flex;
}

.additional-checkboxes.hidden {
  display: none;
  opacity: 0;
  transition: opacity 1s ease-out;
}

.additional-checkboxes.visible {
  display: block;
  opacity: 1;
  transition: opacity 1s ease-in;
}

.hidden {
  display: none;
}
