.alert-component {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  text-align: center;
  z-index: 1000;
  font-size: 18px;
  font-weight: bold;
}

.alert-component.success {
  background-color: #4CAF50;
  color: white;
}

.alert-component.error {
  background-color: #f44336;
  color: white;
}