.panel-choice-rhombus-wrapper {
  display: flex;
  cursor: pointer;
  justify-content: center;
  transition: 0.3s;
}

.panel-choice-rhombus {
  background-color: #777;
  background-size: cover;
  background-position: center;
  width: 100px;
  aspect-ratio: 2/3;
  filter: brightness(70%) hue-rotate(20deg);
  position: relative;
  transition: all 0.1s ease-in-out;
}

.panel-choice-rhombus:hover {
  filter: brightness(50%);
}

.panel-choice-rhombus:hover .info-icon6 {
  opacity: 1;
}

.panel-choice-rhombus-wrapper.selected .info-icon6 {
  opacity: 1;
}

.panel-choice-rhombus-selected {
  filter: brightness(100%) hue-rotate(20deg) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.panel-choice-rhombus::after {
  content: attr(data-hover-text);
  position: absolute;
  width: 100px;
  text-align: center;
  bottom: -25px;
  opacity: 0;
  font-size: 13px;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.panel-choice-rhombus:hover::after {
  opacity: 1;
}

.panel-choice-rhombus-selected::after {
  opacity: 1;
}

.info-icon6 {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  text-align: center;
  line-height: 16px;
  font-size: 14px;
  cursor: pointer;
  border: 2px solid black;
  box-sizing: border-box;
  padding-top: 1px;
  opacity: 0;
  transition: opacity 0.3s;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@media (orientation: landscape) and (max-height: 480px) {
  .panel-choice-rhombus {
    width: 80px;
    aspect-ratio: 2/3;
  }

  .panel-choice-rhombus::after {
    width: 80px;
    font-size: 11px;
  }

  .info-icon6 {
    top: 5%;
    width: 18px;
    height: 18px;
    font-size: 12px;
    line-height: 14px;
  }
}
@media (orientation: landscape) and (max-height: 392px) {
  .panel-choice-rhombus {
    width: 70px;
    aspect-ratio: 2/3;
  }

  .panel-choice-rhombus::after {
    width: 70px;
    font-size: 11px;
  }

  .info-icon6 {
    top: 5%;
    width: 18px;
    height: 18px;
    font-size: 12px;
    line-height: 14px;
  }
}

@media (orientation: landscape) and (max-height: 360px) {
  .panel-choice-rhombus {
    width: 60px;
    aspect-ratio: 2/3;
  }

  .panel-choice-rhombus::after {
    width: 60px;
    font-size: 10px;
  }

  .info-icon6 {
    top: 5%;
    width: 16px;
    height: 16px;
    font-size: 10px;
    line-height: 12px;
  }
}
