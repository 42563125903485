.StayButton {
  background-color: #82c225;
  color: white;
  padding: 5px 30px 7px 30px; /* Adjusted padding: 5px top, 7px bottom */
  outline: none;
  transition: background-color 0.2s;
  border: 3px solid #82c225;
}

.StayButton:focus {
  outline: none;
}

.StayButton:hover {
  background-color: #fff;
  color: #82c225;
  border: 3px solid #82c225;
}
