/* __V3D_TEMPLATE__ - template-based file; delete this line to prevent this file from being updated */

.v3d-container {
  position: relative;
  width: calc(100% - 570px);
  height: 96vh;
  margin-left: 1%;
  margin-top: 2vh;
}



.fullscreen-button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-size: 100% 100%;
    display: none;
    z-index: 1;
}

.fullscreen-open {
    background-image: url('media/fullscreen_open.svg');
}

.fullscreen-close {
    background-image: url('media/fullscreen_close.svg');
}

/* removes tap blinking on ios devices */
* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


@media (orientation: landscape) and (max-height: 480px) {
    .v3d-container {
        height: 93vh;
        width: 50%;
        margin-left: 8.5px;
        margin-top: 15px;
    }
    
}

@media (orientation: portrait) {
    #appui {
        display: none;
    }
    .v3d-container {
        width: 100%;
    }


}

/* Add this CSS to your stylesheet */

@media (orientation: portrait) {
    #loadingOverlay {
      flex-direction: column;
      justify-content: flex-start;
    }
  
    #text2Container {
      order: 1;
      margin-top: 20px;
    }
  
    #logoImage {
      order: 2;
      margin: 20px auto;
    }
  
    #loadingBottomContainer {
      order: 3;
    }
  
    #bottomFlexRow {
      order: 4;
      position: static;
      margin-top: auto;
      margin-bottom: 20px;
    }
  
    #text1 {
      order: 5;
    }
  }
  