.panel-colorgrid {
    gap: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

}

.panel-colorgrid-special {
    margin-left: 50px;
    display: flex;
    justify-content: center;

}


@media (orientation: landscape) and (max-height: 480px) {
    .panel-colorgrid {}

    .panel-colorgrid-special {}
}

@media (orientation: landscape) and (max-height: 392px) {
    .panel-colorgrid {}
}

@media (orientation: landscape) and (max-height: 360px) {
    .panel-colorgrid {}

    .panel-colorgrid-special {}
}