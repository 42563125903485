.info-class {
    position: fixed;
    bottom: 2rem;
    left: 2rem;
}

@media (orientation: landscape) and (max-height: 480px) {
    .info-class {
        display: none;
    }
}