.panel-choice-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 10px;
}

.panel-choice-row {
  display: flex;
  justify-content: center;
  gap: 25px;
 
  flex-wrap: wrap;
  
}

@media (orientation: landscape) and (max-height: 480px) {
  .panel-choice-container {

  }

  .panel-choice-row {

  }
}