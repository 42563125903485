.LeaveButton {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 5px 14px 7px 14px; /* Adjusted padding: 5px top, 7px bottom */
  outline: none;
  transition: background-color 0.2s;
  border: 3px solid #000000;
}

.LeaveButton:focus {
  outline: none;
}

.LeaveButton:hover {
  background-color: #000000;
  color: #ffffff;
  border: 3px solid #000000;
}
