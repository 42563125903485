.dimensionslider {
  display: flex;
  margin-top: 8px;
  margin-bottom: 3px;
  width: 90%;

}


@media (orientation: landscape) and (max-height: 480px) {
  .dimensionslider {
    width: 90%;
  }
}

@media (orientation: landscape) and (max-height: 412px) {
  .dimensionslider {
    width: 90%;
  }

}

@media (orientation: landscape) and (max-height: 392px) {
  .dimensionslider {
    width: 90%;
  }

}