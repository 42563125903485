.logo-icon-div {
    position: fixed;
    top: 2rem;
    left: 2rem;
}

.logo-icon-id {
    max-width: 70px;
    max-height: 70px;
}

@media (orientation: landscape) and (max-height: 480px) {
    .logo-icon-id {
        max-width: 50px;
        max-height: 50px;
    }
}

@media (orientation: landscape) and (max-height: 844px) {}

/*  iPhone 13 mini, iPhone 12 mini, iPhone 11 Pro, iPhone Xs, and iPhone X */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    .logo-icon-div {
        left: 0.5rem;
        top: 0.5rem;
    }

    .logo-icon-id {
        max-width: 50px;
        max-height: 50px;
    }
}

/*  iPhone 13, iPhone 12 & 12 Pro */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    .logo-icon-div {
        left: 0.5rem;
        top: 0.5rem;
    }

    .logo-icon-id {
        max-width: 50px;
        max-height: 50px;
    }
}

/*  iPhone 13 Pro Max & iPhone 12 Pro Max */
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {

    .logo-icon-div {
        left: 0.6rem;
        top: 0.8rem;
    }

    .logo-icon-id {
        max-width: 50px;
        max-height: 50px;
    }
}

/*iPhone 14*/
@media only screen and (width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {

    .logo-icon-div {
        left: 0.5rem;
        top: 0.5rem;
    }

    .logo-icon-id {
        max-width: 50px;
        max-height: 50px;
    }

}

/*iPhone 14 Pro*/
@media only screen and (width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    .logo-icon-div {
        left: 0.5rem;
        top: 0.5rem;
    }

    .logo-icon-id {
        max-width: 50px;
        max-height: 50px;
    }
}

/*iPhone 14 Pro Max*/
@media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    .logo-icon-div {
        left: 0.5rem;
        top: 0.5rem;
    }

    .logo-icon-id {
        max-width: 50px;
        max-height: 50px;
    }
}