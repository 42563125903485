.RedirectHomePopup-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgb(251, 251, 251);
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
  }
  