@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

.default-list-item {
  display: block;
  width: 100%;
  padding: 0.5rem;
  transition: background-color 0.1s ease-in-out;
  cursor: pointer;
}

.default-list-item.selected {
  background-color: #2b2b2b;
  color: #707070;
  transition: all 0.3s ease;
}

.list-text {
  color: #d1d5db;
  font-family: monospace;
}

.list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.list-number {
  color: #000000cc;
  margin-bottom: 0.5rem;
  margin-top: 0.2rem;
  font-size: 1.5em;
}

.list-title {
  color: #000000cc;
  margin-bottom: 0.5rem;
  margin-top: 0.2rem;
  font-size: 1.5em;
  flex: 1;
  text-align: center;
  font-weight: bold;
}

.list-title {
  flex: 1;
  text-align: center;
  font-weight: bold;
}

.list-dots {
  display: flex;
  gap: 0.25rem;
  margin-bottom: 0.25rem;
}

.list-dot {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #ffffff;
}

.list-toggle-icon {
  display: flex;
  font-size: 2rem;
  color: #000000cc;

  text-align: center;

}

.icon-plus {
  color: #000000cc;
  margin-bottom: 0.25rem;
}

.icon-minus {
  color: #000000cc; 
  font-size: 3rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1920px) {

  .list-number,
  .list-title {
    font-size: 1.125em;
  }

  .list-dot {
    width: 0.375rem;
    height: 0.375rem;
  }
}

@media (min-width: 1921px) {
  .list-container {
    min-height: 25em;
    padding-bottom: 2em;
  }
}

@media screen and (max-width: 640px) {

  .list-number,
  .list-title {
    font-size: 1em;
  }

  .list-dot {
    width: 0.25rem;
    height: 0.25rem;
  }
}

.transition-list {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
  display: none;  /* default state is not visible */
}

.transition-list.open {
  max-height: 1000px; /* adjust as necessary */
  display: block; /* make it visible when open */
}

@media (orientation: landscape) and (max-height: 480px) {
  .list-title {
    font-size: 0.9em;
  }

  .list-number {
    font-size: 0.9em;
  }

}

.list-toggle-icon {
  width: 16px;  /* or your desired size */
  height: 16px; /* or your desired size */
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Plus icon */
.list-toggle-icon:before,
.list-toggle-icon:after {
  content: '';
  position: absolute;
  background-color: #000000cc; /* adjust the color as needed */
  border-radius: 1px; /* optional: adds rounded corners */
}

.list-toggle-icon:before {
  width: 2px;  /* width of the line */
  height: 100%; /* height of the vertical line */
 /* shadow for the vertical line */
}

.list-toggle-icon:after {
  width: 100%; /* width of the horizontal line */
  height: 2px;  /* height of the line */
 /* shadow for the horizontal line */
}

/* Minus icon */
.list-header.open .list-toggle-icon:before {
  width: 100%; /* full width to make a minus */
  height: 2px;  /* line thickness */
 /* shadow for the minus line */
}

.list-header.open .list-toggle-icon:after {
  display: none; /* hide the vertical part for minus */
}