.scrollable-dropdown-container {
  max-height: 700px;
  overflow-y: auto;
}

.information-container {
  margin-top: 10px;
  font-family: "Montserrat", sans-serif;
  color: #555;
  margin-bottom: 15px;

  border-bottom: 1px solid #00000033;
}

.information-header {
  margin-bottom: 10px;
  font-size: 14px;
  margin-left: 2.55vw;
  font-weight: bold;
}


.information-text {
  margin-bottom: 10px;
  font-size: 12px;
  margin-left: 2.55vw;
}

@media screen and (min-resolution: 144dpi) {

  .information-container {
    margin-top: 8px; /* 10px * 1.5 */
    margin-bottom: 12px; /* 15px * 1.5 */
    border-bottom: 1px solid #00000033; /* 1px * 1.5 */
  }

  .information-header {
    margin-bottom: 10px; /* 10px * 1.5 */
    font-size: 12px; /* 14px * 1.5 */
    margin-left: 2vw; /* 2.55vw * 1.5 */
  }

  .information-text {
    margin-bottom: 15px; /* 10px * 1.5 */
    font-size: 10px; /* 12px * 1.5 */
    margin-left: 2vw; /* 2.55vw * 1.5 */
  }
}
