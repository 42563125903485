.redirect-button {
  position: fixed;
  bottom: 2%;
  z-index: 1000;
  right: 28.7%; /* Initial position relative to the viewport */
  background: none; /* Ensure no background behind the icon */
  transition: background-color 0.2s, border-color 0.2s;
  cursor: pointer;
}

.redirect-icon {
  width: 42px; /* Adjust width as needed */
  height: 42px; /* Adjust height as needed */
}

@media (orientation: landscape) and (max-height: 480px) {
  .redirect-button {
    display: none;
  }
}

@media (orientation: landscape) and (max-height: 390px) {
  .redirect-button {
    display: none;
  }
}

@media (orientation: landscape) and (max-height: 375px) {
  .redirect-button {
    display: none;
  }
}

@media (orientation: portrait) {
  .redirect-button {
    display: none;
  }
}
